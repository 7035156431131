<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="row">
      <div class="col-xl-8 col-lg-12 col-md-12">
        <div class="card mb-0">
          <div class="card-header">
            <h3 class="card-title">Blog Güncelle</h3>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-12">
                <div class="form-group">
                  <label class="form-label" for="blogName"
                    >Makale Başlığı</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="blogName"
                    placeholder="Makale Başlığı"
                    autocomplete="off"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label" for="content">Makale İçeriği</label>
                  <div id="content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-12 col-md-12">
        <div class="card mb-0">
          <div class="card-header">
            <button class="btn btn-primary w-100" @click="save" id="saveButton">
              Güncelle
            </button>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-12">
                <div class="form-group">
                  <label class="form-label" for="blogDescription"
                    >Makale Açıklaması</label
                  >
                  <textarea
                    type="text"
                    class="form-control"
                    id="blogDescription"
                    v-model="blogDescription"
                    placeholder="Makale Açıklaması"
                    autocomplete="off"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="form-label" for="category">Kategori</label>
                  <select
                    type="text"
                    class="form-control"
                    id="category"
                    v-model="category"
                    autocomplete="off"
                  >
                    <option value="0" selected disabled>Kategori Seçin</option>
                    <option
                      v-for="(item, i) in categoryList"
                      :key="i"
                      :value="item.id"
                    >
                      {{ item.category_name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label" for="tags">Etiketler</label>
                  <input
                    type="text"
                    class="form-control"
                    id="tags"
                    v-model="tags"
                    placeholder="Etiket"
                    autocomplete="off"
                  />
                </div>

                <div class="modal fade" tabindex="-1" id="cover" role="dialog">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-body" style="margin-bottom: -21px">
                        <croppa
                          v-model="myCroppa"
                          :width="500"
                          style="margin-left: -17px; margin-top: -17px"
                          :height="300"
                          :placeholder="'Resim Seçin 1000x600'"
                          :accept="'image/*'"
                          :file-size-limit="0"
                          :quality="2"
                          :zoom-speed="3"
                          :disabled="false"
                          :disable-drag-and-drop="false"
                          :disable-click-to-choose="false"
                          :disable-drag-to-move="false"
                          :disable-scroll-to-zoom="false"
                          :disable-rotation="false"
                          :prevent-white-space="false"
                          :reverse-scroll-to-zoom="false"
                          :show-remove-button="true"
                          :remove-button-color="'red'"
                          :remove-button-size="0"
                          canvas-color="transparent"
                        ></croppa>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          @click="uploadImage"
                          id="uploadImageButton"
                          class="btn btn-primary"
                        >
                          Resmi Yükle
                        </button>
                        <button
                          type="button"
                          @click="coverClose"
                          class="btn btn-primary"
                        >
                          Kapat
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  v-if="imageUrl != '' || imageUrl != null"
                  :src="'https://storage.terapivitrini.com/' + imageUrl"
                />
                <button class="btn btn-primary w-100" @click="cover">
                  Kapak Resmi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import Swal from "sweetalert2";
export default {
  created() {
    document.title = "Blog Güncelle";
    this.$store
      .dispatch("profileBlogGet", { id: this.$route.params.id })
      .then((value) => {
        this.blogName = value.list.title;
        this.blogDescription = value.list.description;
        this.content = value.list.content;
        $("#content").summernote("code", this.content);
        this.tags = value.list.tags.toString();
        this.imageUrl = value.list.thumbnail;
        this.category = value.list.categories.category_id;
        this.status = value.list.publish;
      });
    this.$store.dispatch("categoryList").then((value) => {
      this.categoryList = value;
    });
  },
  data() {
    return {
      myCroppa: {},
      blogName: "",
      blogDescription: "",
      content: "",
      tags: "",
      imageUrl: "",
      category: "0",
      categoryList: [],
      status: 0,
    };
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    uploadImage() {
      document.getElementById("uploadImageButton").innerText =
        "Resim Yükleniyor...";
      document.getElementById("uploadImageButton").disabled = true;
      let base64img = this.myCroppa.generateDataUrl();

      let file = this.dataURLtoFile(
        base64img,
        this.shortLinkGenerator() + ".jpg"
      );
      let formData = new FormData();
      formData.append("file", file);
      this.$store.dispatch("blogCoverUpload", formData).then((response) => {
        document.getElementById("uploadImageButton").innerText = "Resmi Yükle";
        document.getElementById("uploadImageButton").disabled = false;
        if (response.type == "error") {
          if (response.message == "ERRxUAR") {
            this.$vToastify.warning(
              "Onay sürecindeki profil güncellenemez",
              "Uyarı!"
            );
          }
        } else {
          this.$vToastify.success(
            "Resim başarılı bir şekilde yüklendi",
            "Başarılı!"
          );
          $("#cover").modal("hide");
          this.imageUrl = response.link;
        }
      });
    },
    cover() {
      $("#cover").modal("show");
    },
    coverClose() {
      $("#cover").modal("hide");
    },
    shortLinkGenerator() {
      let t = this.blogName.toLowerCase();
      return t
        .replaceAll("ğ", "g")
        .replaceAll("ü", "u")
        .replaceAll("ş", "s")
        .replaceAll("ı", "i")
        .replaceAll("ö", "o")
        .replaceAll("ç", "c")
        .replaceAll("?", "-")
        .replaceAll(" ", "-");
    },
    save() {
      if (this.status == 1) {
        Swal.fire({
          title: "Onaylı makaleyi güncellemek istediğine emin misin?",
          text: "Bu işlem sonrası yapılan değişiklikler için eğtim onaya gönderilecek. Onay sürecinde eğitimde değişiklik yapamazsınız, devam etmek istiyor musun?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Devam",
          cancelButtonText: "Vazgeç",
        }).then((result) => {
          if (result.isConfirmed) {
            this.process();
          }
        });
      } else {
        this.process();
      }
    },
    process() {
      this.content = $("#content").summernote("code");
      if (this.blogName == null || this.blogName == "") {
        this.$vToastify.warning("Blog başlığı boş olamaz", "Uyarı!");
      } else if (
        this.category == null ||
        this.category == "" ||
        this.category == "0"
      ) {
        this.$vToastify.warning("Kategori seçilmedi!", "Uyarı!");
      } else if (this.blogDescription == null || this.blogDescription == "") {
        this.$vToastify.warning("Açıklama girilmedi", "Uyarı!");
      } else if (this.content == null || this.content == "") {
        this.$vToastify.warning("İçerik girilmedi", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let blogUpdate = {
          id: this.$route.params.id,
          title: this.blogName,
          category: this.category,
          description: this.blogDescription,
          content: this.content,
          imageUrl: this.imageUrl,
        };
        this.$store.dispatch("blogUpdate", blogUpdate).then((value) => {
          if (value.type == "error") {
            if (value.message == "ERRxABNC") {
              this.$vToastify.warning(
                "Onay sürecindeki blog güncellenemez",
                "Uyarı!"
              );
            }
          } else {
            if (this.status == 1) {
              this.$vToastify.success(
                "Blog güncellendi ve yeniden onaya gönderildi",
                "Başarılı!"
              );
              this.$router.push("/profile/blogs");
            }
            this.$vToastify.success(
              "Blog başarılı bir şekilde güncellendi",
              "Başarılı!"
            );
          }
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
        });
      }
    },
  },
  components: {},
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://terapivitrini.com/assets/plugins/summer-note/summernote1.js"
    );
    document.head.appendChild(recaptchaScript);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          [
            "style",
            ["highlight", "bold", "italic", "underline", "clear", "style"],
          ],
          ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph", "hr"]],
          ["insert", ["tableofcontent"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["view", ["fullscreen", "codeview", "help"]],
        ],
      });
    });
  },
};
</script>